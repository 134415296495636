.cui-login {
    -webkit-background-size: cover;
            background-size: cover;
    background-color: #f2f4f8;
    -webkit-transition: background 0.5s;
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.cui-login-fullscreen {
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    /*background-color: #eeeeee;*/
    /*background-image: url("../../img/loginBackground2.jpeg");*/
}

.cui-login-block {
    padding: 6.15rem 3.07rem 6.15rem;
    width: 100%;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
    position: relative;
    z-index: 2;
}

@media (max-width: 991px) {
    .cui-login-block {
        padding: 6.15rem 1.53rem 6.15rem;
    }
}

.cui-login-block-inner {
    min-width: 23.07rem;
    max-width: 38.46rem;
    margin: 0 auto;
    padding: 3.84rem 3.07rem 1.53rem;
    -webkit-border-radius: 10px;
            border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
}

.cui-login-block-form {
    position: relative;
    z-index: 2;
}

.cui-login-block-promo {
    padding: 0 0 4.61rem;
    max-width: 61.53rem;
    font-size: 1.23rem;
    line-height: 2rem;
    text-align: center;
    margin: 0 auto;
}

.cui-login-block-sidebar {
    display: none;
}

@media (min-width: 992px) {
    .cui-login-block-extended .cui-login-block-inner {
        max-width: 61.53rem;
        padding: 6.15rem 6.15rem 3.84rem;
    }
    .cui-login-block-extended .cui-login-block-form {
        margin-right: 23.07rem;
    }
    .cui-login-block-extended .cui-login-block-sidebar {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 23.07rem;
        padding: 6.15rem 3.07rem;
        color: #fff;
        background: #222034;
    }
    .cui-login-block-extended .cui-login-block-sidebar-item {
        padding-left: 1.53rem;
        border-left: 2px solid #74708d;
        margin-bottom: 1.53rem;
        color: #74708d;
    }
    .cui-login-block-extended .cui-login-block-sidebar-title {
        margin-bottom: 1.53rem;
        line-height: 1.5;
    }
    .cui-login-block-extended .cui-login-block-sidebar-place {
        font-size: 1.07rem;
        font-weight: bold;
        position: absolute;
        z-index: 2;
        bottom: 3.07rem;
        left: 3.07rem;
    }
}

.cui-login-header {
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start;
    width: 100%;
    color: #fff;
    padding: 3.07rem;
}

@media (max-width: 991px) {
    .cui-login-header {
        padding: 3.07rem 1.53rem 3.07rem;
    }
}

.cui-login-header-logo img {
    max-width: 11.53rem;
    max-height: 3.84rem;
}

.cui-login-header-menu {
    text-align: right;
    margin-top: 0.76rem;
}

@media (max-width: 991px) {
    .cui-login-header-menu {
        text-align: left;
    }
}

.cui-login-header-menu ul {
    /*font-size: 1.23rem;*/
}

.cui-login-header-menu ul li {
    margin-right: 1.53rem;
    /*text-transform: uppercase;*/
}

.cui-login-header-menu ul li:last-child {
    margin-right: 0;
}

.cui-login-header-menu ul li.active a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.cui-login-header-menu ul li a {
    color: #212121 !important;
    line-height: 1.4;
    display: inline-block;
    margin-right: 0.76rem;
    font-weight: bold;
}

.cui-login-header-menu ul li a:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.cui-login-footer {
    -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
            align-self: flex-end;
    width: 100%;
    color: white;
    padding: 3.07rem;
}

@media (max-width: 991px) {
    .cui-login-footer {
        padding: 3.07rem 1.53rem 3.07rem;
    }
}

.cui-login-footer ul {
    margin-bottom: 0;
}

.cui-login-footer ul li {
    margin-right: 1.53rem;
    /*text-transform: uppercase;*/
}

.cui-login-footer ul li:last-child {
    margin-right: 0;
}

.cui-login-footer ul li a {
    color: #424242;
    opacity: 0.6;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    line-height: 1.4;
    display: inline-block;
    margin-right: 0.76rem;
}

.cui-login-footer ul li a:hover {
    opacity: 1;
}
