/* TABS */
.nav-tabs-horizontal .nav-tabs {
    border-bottom: 1px solid #e4e9f0;
}

.nav-tabs-horizontal .nav-tabs .nav-item {
    margin-bottom: -2px;
    cursor: pointer;
}

.nav-tabs-horizontal .nav-tabs .nav-item .nav-link {
    border: none;
    border-bottom: 3px solid transparent;
}

.nav-tabs-horizontal .nav-tabs .nav-item .nav-link.active, .nav-tabs-horizontal .nav-tabs .nav-item .nav-link:focus {
    border-bottom-color: #0190fe !important;
}

.nav-tabs-horizontal .nav-tabs .nav-item .nav-link:hover {
    border-bottom-color: #b8beca;
}

.nav-tabs-vertical:after {
    clear: both;
    content: '';
    display: block;
}

.nav-tabs-vertical .nav-tabs {
    border-bottom: none;
    border-right: 1px solid #e4e9f0;
    float: left;
    margin-right: 30px;
    display: block;
}

.nav-tabs-vertical .nav-tabs .nav-item {
    margin: 0 -2px 1px 0;
    float: none;
    cursor: pointer;
}

.nav-tabs-vertical .nav-tabs .nav-item .nav-link {
    border: none;
    border-right: 3px solid transparent;
    padding-left: 0;
    -webkit-border-radius: 0;
            border-radius: 0;
}

.nav-tabs-vertical .nav-tabs .nav-item .nav-link.active, .nav-tabs-vertical .nav-tabs .nav-item .nav-link:focus {
    border-right-color: #0190fe !important;
}

.nav-tabs-vertical .nav-tabs .nav-item .nav-link:hover {
    border-right-color: #b8beca;
}
