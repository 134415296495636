/* THEMES: RED */
body.cui-theme-red .cui-menu-left-inner {
    background: #fff;
    border-right: 1px solid #e4e9f0;
}

body.cui-theme-red .cui-menu-left-trigger {
    background: #e4e9f0;
}

body.cui-theme-red .cui-menu-left-logo {
    background: #d24667;
}

body.cui-theme-red .cui-menu-left-icon {
    color: #c7c5d1;
}

body.cui-theme-red .cui-menu-left-divider {
    background: #e4e9f0;
}

body.cui-theme-red .cui-menu-left-item > a {
    color: #74708d;
}

body.cui-theme-red .cui-menu-left-item-active > a, body.cui-theme-red .cui-menu-left-item:hover > a, body.cui-theme-red .cui-menu-left-item:active > a, body.cui-theme-red .cui-menu-left-item:focus > a {
    background: #d24667;
    color: #fff;
}

body.cui-theme-red .cui-menu-left-item-active > a:before, body.cui-theme-red .cui-menu-left-item-active > a:after, body.cui-theme-red .cui-menu-left-item:hover > a:before, body.cui-theme-red .cui-menu-left-item:hover > a:after, body.cui-theme-red .cui-menu-left-item:active > a:before, body.cui-theme-red .cui-menu-left-item:active > a:after, body.cui-theme-red .cui-menu-left-item:focus > a:before, body.cui-theme-red .cui-menu-left-item:focus > a:after {
    background: #fff !important;
}

body.cui-theme-red .cui-menu-left-item-active > a > .cui-menu-left-icon, body.cui-theme-red .cui-menu-left-item:hover > a > .cui-menu-left-icon, body.cui-theme-red .cui-menu-left-item:active > a > .cui-menu-left-icon, body.cui-theme-red .cui-menu-left-item:focus > a > .cui-menu-left-icon {
    color: #fff;
}

body.cui-theme-red .cui-menu-left-submenu-toggled.cui-menu-left-item {
    background: #f4f6f9;
    color: #fff;
}

body.cui-theme-red .cui-menu-left-submenu-toggled.cui-menu-left-item > a {
    background: #d24667;
    color: #fff;
}

body.cui-theme-red .cui-menu-left-submenu-toggled.cui-menu-left-item > a .cui-menu-left-icon {
    color: #fff;
}

body.cui-theme-red .cui-menu-left-submenu-toggled.cui-menu-left-item > a:before, body.cui-theme-red .cui-menu-left-submenu-toggled.cui-menu-left-item > a:after {
    background: #fff !important;
}

body.cui-theme-red .cui-menu-left-submenu > .cui-menu-left-list {
    background: #f4f6f9;
}

body.cui-theme-red .cui-menu-left-submenu.cui-menu-left-item > a:after {
    border-color: transparent #c7c5d1 transparent transparent;
}

body.cui-theme-red .cui-menu-left .jspScrollable .jspDrag:after {
    background: #e2e1e8 !important;
}

body.cui-theme-red .cui-menu-top-inner {
    background: #fff;
    border-right: 1px solid #e4e9f0;
}

body.cui-theme-red .cui-menu-top-trigger {
    background: #e4e9f0;
}

body.cui-theme-red .cui-menu-top-logo {
    background: #d24667;
}

body.cui-theme-red .cui-menu-top-icon {
    color: #c7c5d1;
}

body.cui-theme-red .cui-menu-top-divider {
    background: #e4e9f0;
}

body.cui-theme-red .cui-menu-top-item > a {
    color: #74708d;
}

body.cui-theme-red .cui-menu-top-item-active > a, body.cui-theme-red .cui-menu-top-item:hover > a, body.cui-theme-red .cui-menu-top-item:active > a, body.cui-theme-red .cui-menu-top-item:focus > a {
    background: #d24667;
    color: #fff;
}

body.cui-theme-red .cui-menu-top-item-active > a:before, body.cui-theme-red .cui-menu-top-item-active > a:after, body.cui-theme-red .cui-menu-top-item:hover > a:before, body.cui-theme-red .cui-menu-top-item:hover > a:after, body.cui-theme-red .cui-menu-top-item:active > a:before, body.cui-theme-red .cui-menu-top-item:active > a:after, body.cui-theme-red .cui-menu-top-item:focus > a:before, body.cui-theme-red .cui-menu-top-item:focus > a:after {
    background: #fff !important;
}

body.cui-theme-red .cui-menu-top-item-active > a > .cui-menu-top-icon, body.cui-theme-red .cui-menu-top-item:hover > a > .cui-menu-top-icon, body.cui-theme-red .cui-menu-top-item:active > a > .cui-menu-top-icon, body.cui-theme-red .cui-menu-top-item:focus > a > .cui-menu-top-icon {
    color: #fff;
}

body.cui-theme-red .cui-menu-top-submenu-toggled.cui-menu-top-item {
    background: #f4f6f9;
    color: #fff;
}

body.cui-theme-red .cui-menu-top-submenu-toggled.cui-menu-top-item > a {
    background: #d24667;
    color: #fff;
}

body.cui-theme-red .cui-menu-top-submenu-toggled.cui-menu-top-item > a .cui-menu-top-icon {
    color: #fff;
}

body.cui-theme-red .cui-menu-top-submenu-toggled.cui-menu-top-item > a:before, body.cui-theme-red .cui-menu-top-submenu-toggled.cui-menu-top-item > a:after {
    background: #fff !important;
}

body.cui-theme-red .cui-menu-top-submenu > .cui-menu-top-list {
    background: #f4f6f9;
}

body.cui-theme-red .cui-menu-top-submenu.cui-menu-top-item > a:after {
    border-color: transparent #c7c5d1 transparent transparent;
}
