/*  "MEASUREMENTS" STYLES */
.font-size-0 {
    font-size: 0rem;
}

.font-size-10 {
    font-size: 0.76rem;
}

.font-size-12 {
    font-size: 0.92rem;
}

.font-size-14 {
    font-size: 1.07rem;
}

.font-size-16 {
    font-size: 1.23rem;
}

.font-size-18 {
    font-size: 1.38rem;
}

.font-size-20 {
    font-size: 1.53rem;
}

.font-size-24 {
    font-size: 1.84rem;
}

.font-size-26 {
    font-size: 2rem;
}

.font-size-30 {
    font-size: 2.3rem;
}

.font-size-36 {
    font-size: 2.76rem;
}

.font-size-40 {
    font-size: 3.07rem;
}

.font-size-50 {
    font-size: 3.84rem;
}

.font-size-60 {
    font-size: 4.61rem;
}

.font-size-70 {
    font-size: 5.38rem;
}

.font-size-80 {
    font-size: 6.15rem;
}

.height-100 {
    height: 7.69rem;
}

.height-200 {
    height: 15.38rem;
}

.height-300 {
    height: 23.07rem;
}

.height-400 {
    height: 30.76rem;
}

.height-500 {
    height: 38.46rem;
}

.height-600 {
    height: 46.15rem;
}

.height-700 {
    height: 53.84rem;
}

.width-50 {
    width: 3.84rem;
}

.width-100 {
    width: 7.69rem;
}

.width-200 {
    width: 15.38rem;
}

.width-300 {
    width: 23.07rem;
}

.width-400 {
    width: 30.76rem;
}

.width-500 {
    width: 38.46rem;
}

.width-600 {
    width: 46.15rem;
}

.width-700 {
    width: 53.84rem;
}

.width-20p {
    width: 20%;
}

.width-40p {
    width: 40%;
}

.width-50p {
    width: 50%;
}

.width-60p {
    width: 60%;
}

.width-80p {
    width: 80%;
}

.width-100p {
    width: 100%;
}
