/*  "LAYOUT" STYLES */
.cui-layout {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
        -ms-flex: auto;
            flex: auto;
    min-height: 100vh;
    min-width: 0;
}

.cui-layout.cui-layout-has-sider {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
}

.cui-layout-content {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
        -ms-flex: auto;
            flex: auto;
    min-height: 0;
}

.cui-layout-footer {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.cui-layout-header {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    height: 64px;
}
