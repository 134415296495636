/* MODAL */
.modal .modal-content {
    border: none;
}

.modal .modal-header .close {
    background: #fff;
}

.modal.modal-size-small {
    padding-left: 10px;
    padding-right: 10px;
}

.modal.modal-size-small .modal-dialog {
    max-width: 300px;
    width: auto;
}

@media (max-width: 543px) {
    .modal.modal-size-small .modal-dialog {
        max-width: none;
    }
}

.modal.modal-size-large {
    padding-left: 10px;
    padding-right: 10px;
}

.modal.modal-size-large .modal-dialog {
    max-width: 980px;
    width: auto;
}

.modal-backdrop {
    background: #0e0b20;
}

.modal-backdrop.in {
    opacity: 0.3;
}
