/* THEMES: GREEN */
.cui-theme-green .cui-topbar {
    background: #528e74;
    border-bottom-color: #528e74;
}

.cui-theme-green .cui-topbar-mini-chart {
    color: #fff;
}

.cui-theme-green .cui-topbar-mini-chart-inner i {
    background: #fff;
}

.cui-theme-green .cui-topbar-search {
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

.cui-theme-green .cui-topbar-search input {
    background: #fff;
}

.cui-theme-green .cui-topbar-item > .dropdown > a {
    color: #fff;
}

.cui-theme-green .cui-topbar-item > .dropdown > a:hover {
    color: #e4e9f0;
}

.cui-theme-green .cui-menu-left-inner {
    background: #fff;
    border-right: 1px solid #e4e9f0;
}

.cui-theme-green .cui-menu-left-trigger {
    background: #e4e9f0;
}

.cui-theme-green .cui-menu-left-logo {
    background: #528e74;
}

.cui-theme-green .cui-menu-left-item > a {
    color: #74708d;
}

.cui-theme-green .cui-menu-left-item-active > a, .cui-theme-green .cui-menu-left-item:hover > a, .cui-theme-green .cui-menu-left-item:active > a, .cui-theme-green .cui-menu-left-item:focus > a {
    background: #528e74;
    color: #fff;
}

.cui-theme-green .cui-menu-left-item-active > a:before, .cui-theme-green .cui-menu-left-item-active > a:after, .cui-theme-green .cui-menu-left-item:hover > a:before, .cui-theme-green .cui-menu-left-item:hover > a:after, .cui-theme-green .cui-menu-left-item:active > a:before, .cui-theme-green .cui-menu-left-item:active > a:after, .cui-theme-green .cui-menu-left-item:focus > a:before, .cui-theme-green .cui-menu-left-item:focus > a:after {
    background: #fff !important;
}

.cui-theme-green .cui-menu-left-item-active > a > .cui-menu-left-icon, .cui-theme-green .cui-menu-left-item:hover > a > .cui-menu-left-icon, .cui-theme-green .cui-menu-left-item:active > a > .cui-menu-left-icon, .cui-theme-green .cui-menu-left-item:focus > a > .cui-menu-left-icon {
    color: #fff;
}

.cui-theme-green .cui-menu-left-icon {
    color: #c7c5d1;
}

.cui-theme-green .cui-menu-left-divider {
    background: #e4e9f0;
}

.cui-theme-green .cui-menu-left-submenu-toggled.cui-menu-left-item {
    color: #fff;
    background: #f4f6f9;
}

.cui-theme-green .cui-menu-left-submenu-toggled.cui-menu-left-item > a {
    background: #528e74;
    color: #fff;
}

.cui-theme-green .cui-menu-left-submenu-toggled.cui-menu-left-item > a .cui-menu-left-icon {
    color: #fff;
}

.cui-theme-green .cui-menu-left-submenu > .cui-menu-left-list {
    background: #f4f6f9;
}

.cui-theme-green .cui-menu-left .jspScrollable .jspDrag:after {
    background: #e2e1e8 !important;
}

.cui-theme-green .cui-menu-top-inner {
    background: #fff;
    border-right: 1px solid #e4e9f0;
}

.cui-theme-green .cui-menu-top-trigger {
    background: #e4e9f0;
}

.cui-theme-green .cui-menu-top-logo {
    background: #528e74;
}

.cui-theme-green .cui-menu-top-item > a {
    color: #74708d;
}

.cui-theme-green .cui-menu-top-item-active > a, .cui-theme-green .cui-menu-top-item:hover > a, .cui-theme-green .cui-menu-top-item:active > a, .cui-theme-green .cui-menu-top-item:focus > a {
    background: #528e74;
    color: #fff;
}

.cui-theme-green .cui-menu-top-item-active > a:before, .cui-theme-green .cui-menu-top-item-active > a:after, .cui-theme-green .cui-menu-top-item:hover > a:before, .cui-theme-green .cui-menu-top-item:hover > a:after, .cui-theme-green .cui-menu-top-item:active > a:before, .cui-theme-green .cui-menu-top-item:active > a:after, .cui-theme-green .cui-menu-top-item:focus > a:before, .cui-theme-green .cui-menu-top-item:focus > a:after {
    background: #fff !important;
}

.cui-theme-green .cui-menu-top-item-active > a > .cui-menu-top-icon, .cui-theme-green .cui-menu-top-item:hover > a > .cui-menu-top-icon, .cui-theme-green .cui-menu-top-item:active > a > .cui-menu-top-icon, .cui-theme-green .cui-menu-top-item:focus > a > .cui-menu-top-icon {
    color: #fff;
}

.cui-theme-green .cui-menu-top-icon {
    color: #c7c5d1;
}

.cui-theme-green .cui-menu-top-divider {
    background: #e4e9f0;
}

.cui-theme-green .cui-menu-top-submenu-toggled.cui-menu-top-item {
    color: #fff;
    background: #f4f6f9;
}

.cui-theme-green .cui-menu-top-submenu-toggled.cui-menu-top-item > a {
    background: #528e74;
    color: #fff;
}

.cui-theme-green .cui-menu-top-submenu-toggled.cui-menu-top-item > a .cui-menu-left-icon {
    color: #fff;
}

.cui-theme-green .cui-menu-top-submenu > .cui-menu-top-list {
    background: #f4f6f9;
}
