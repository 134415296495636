@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
.error {
	border: 1px solid red;
}
.fs-18 {
	font-size: 17px;
	color: rgb(37, 150, 190);
}
.fs-18 .bi-chevron-up::before {
	font-weight: 800 !important;
}
.err-msg {
	color: red;
	font-size: 12px;
}
.week {
	display: flex;
	flex-direction: row;
	margin-left: 30%;
	font-size: 10px;
}

.week:before,
.week:after {
	content: "";
	flex: 1 1;
	border-bottom: 1px solid #000;
	margin: auto;
}
/* error page */
#notfound {
	position: relative;
	height: 50vh;
}
#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.notfound {
	max-width: 920px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
}
.notfound .notfound-404 {
	position: absolute;
	height: 100px;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: -1;
}
.notfound .notfound-404 h1 {
	font-family: "Maven Pro", sans-serif;
	color: #ececec;
	font-weight: 900;
	font-size: 276px;
	margin: 0px;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.notfound h2 {
	font-family: "Maven Pro", sans-serif;
	font-size: 46px;
	color: #74708d;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0px;
}
.notfound p {
	font-family: "Maven Pro", sans-serif;
	font-size: 16px;
	color: #74708d;
	font-weight: 400;
	margin-top: 15px;
}
.notfound a {
	font-family: "Maven Pro", sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

.custom-switch {
	padding-left: 3.5rem;
}
.custom-switch .custom-control-label::before {
	left: -3.5rem;
	width: 3rem;
	pointer-events: all;
	border-radius: 0.5625rem;
}
.custom-switch .custom-control-label::after {
	top: 0.20313rem;
	left: -3.5rem;
	width: 1.125rem;
	height: 1.125rem;
	background-color: #b1c2d9;
	border-radius: 0.5625rem;
	transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.custom-switch .custom-control-label::after {
		transition: none;
	}
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
	background-color: #e3ebf6;
	transform: translateX(2rem);
}
.custom-switch
	.custom-control-input:disabled:checked
	~ .custom-control-label::before {
	background-color: rgba(44, 123, 229, 0.5);
}

.custom-switch {
	min-height: 1.5rem;
}
.custom-switch .custom-control-label::before {
	top: 0;
	height: 1.5rem;
	border-radius: 0.75rem;
}
.custom-switch .custom-control-label::after {
	top: 0.1875rem;
	left: -3.3125rem;
	background-color: #ffffff;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
	background-color: #ffffff;
	transform: translateX(1.5rem);
}

.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	cursor: pointer;
}
.custom-control-label::before {
	position: absolute;
	top: 0.20313rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	background-color: #e3ebf6;
	border: #b1c2d9 solid 0;
}
.custom-control-label::after {
	position: absolute;
	top: 0.20313rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: "";
	background: no-repeat 50% / 50% 50%;
}
