/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    /*top: 15px;*/
    /*right: 15px;*/

    width: 10.76rem;
    padding: 0.61rem 1.15rem;
    background: #fff;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #e4e9f0;
    right: auto;
    left: 50%;
    top: 1rem;
    margin-left: -5.62rem;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    /*border-top-color: #29d;*/
    /*border-left-color: #29d;*/
    border-radius: 50%;

    border-top-color: #0190fe;
    border-left-color: #0190fe;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#nprogress .bar {
    height: 3px;
    background: #0190fe;
}

#nprogress .spinner:after {
    content: 'Loading...';
    display: inline-block;
    position: absolute;
    top: 0.53rem;
    left: 3.69rem;
}
